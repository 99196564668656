import React from "react"
import styled from "styled-components"

const PageHeaderSection = styled.section`
  text-align: center;
`
const PageHeaderSubTitle = styled.span`
  font-family: "OpenSans-Regular";
  font-size: 1.75rem;
`
const PageHeaderFrom = styled.span`
  font-family: "Jost-Regular";
  font-size: 1rem;
`

export default function PageHeader(props) {
  return (
    <PageHeaderSection>
      <h1>
        You've received a gift
        <br />
        <PageHeaderFrom>
          {props.giftDetails && props.giftDetails.sender_name && "from"}
        </PageHeaderFrom>
        <br />
        <PageHeaderSubTitle>
          {props.giftDetails && props.giftDetails.sender_name}
        </PageHeaderSubTitle>
      </h1>
    </PageHeaderSection>
  )
}

const StarPageHeaderElement = styled.h1`
  font-family: "CamptonBook";
`
const StarPageHeaderSection = styled.section`
  text-align: center;
`
const StarPageHeaderSubTitle = styled.span`
  font-family: "CamptonLight";
  font-size: 2rem;
`
const StarPageHeaderFrom = styled.span`
  font-family: "CamptonLight";
  font-size: 1.75rem;
`
const StarPageMessage = styled.p`
  font-family: "CamptonLight";
  font-size: 1rem;
`

export function StarPageHeader(props) {
  return (
    <StarPageHeaderSection>
      <StarPageHeaderElement>
        You've received a gift
        <br />
        <StarPageHeaderFrom>
          {props.giftDetails && props.giftDetails.sender_name && "from"}
        </StarPageHeaderFrom>
        <br />
        <StarPageHeaderSubTitle>
          {props.giftDetails && props.giftDetails.sender_name}
        </StarPageHeaderSubTitle>
        {false && (
          <>
            <br />
            <StarPageMessage>
              {props.giftDetails && props.giftDetails.greeting_message}
            </StarPageMessage>
          </>
        )}
      </StarPageHeaderElement>
    </StarPageHeaderSection>
  )
}
